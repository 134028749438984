.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  
}

.App header {
  font-size: 3vw;
  font-weight: bold;
  text-align: center;
  margin: 0;
  width: 100%;
  background-color: rgba(255,255,255,0.4);
}

.App header h1{
  margin: 0;
  
}

.inputs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  position: fixed;
  bottom: 0px;
  background-color: rgba(255,255,255,0.4);
}

.inputs input{
  font-size:1.5rem;
  width: 50px;
  text-align: center;
  
}

.inputs button.start{
  font-size: 1.5rem;
  background-color: rgba(147,200,120,1);
  border-radius: 10px;
  border-color: rgb(74, 102, 59);
  border-width: 1px;
}

.inputs button.start:hover {
  background-color: rgb(103, 139, 85);
}

.inputs button.reset{
  font-size: 1.5rem;
  background-color: rgba(197, 13, 13, 1);
  border-radius: 10px;
  border-color: rgb(124, 10, 10);
  border-width: 1px;
  
}

.inputs button.reset:hover {
  background-color: rgb(124, 10, 10);
}
.inputs > div {
  margin-right: 20px;
  font-size: 1.5rem;
}

.timer-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

}

.timer-grid > div {
  width: 25vw;
  height: 22vh;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.timer-grid > div > div:first-child {
  font-size: 1.5rem;
}

.timer-grid > div > div:last-child {
  font-size: 3rem;
  color: black;
  font-weight: bold;
}


.reset-button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom:0px;
}

.reset-button button {
  font-size: 1.5rem;
}
